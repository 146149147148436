angular.module('Artakija')

    .controller('LoginController',
        function LoginController($scope, $rootScope, $state, LS, ApiService, $api, $window) {
            console.log('Initialize LoginController');

            $scope.submit = function () {
                if (!$scope.username) {
                    $window.alert('Įveskite vartotojo vardą');
                    return;
                }

                if (!$scope.password) {
                    $window.alert('Įveskite slaptažodį');
                    return;
                }

                $scope.loading = true;

                var body = {
                    "username": $scope.username,
                    "password": $scope.password
                };

                ApiService.apiCall('login', null, body, null, true).then(function (data) {
                    if (!data.isError) {
                        if (data.error) {
                            if (data.error === 'Invalid credentials.') {
                                $window.alert('Neteisingi prisijungimo duomenys.');
                            } else {
                                $window.alert('Nenumatyta klaida');
                            }
                        }

                        if (data.result && data.result.token) {
                            if (data.result.user) {
                                var roles = data.result.user.roles;
                                $api.header('X-AUTH-TOKEN', data.result.token);

                                if (roles.indexOf('ROLE_ADMIN') !== -1) {
                                    LS.setParam('token', data.result.token);
                                    LS.setParam('user', data.result.user);

                                    if (data.result.user) {
                                        $rootScope.user = data.result.user;
                                    }

                                    if ($rootScope.user.accessPoints.includes('home')) {
                                        $state.go('home');
                                    } else {
                                        $state.go($rootScope.user.accessPoints[0])
                                    }

                                    return;
                                } else if (roles.indexOf('ROLE_CONSUMER') !== -1) {
                                    LS.setParam('token', data.result.token);
                                    LS.setParam('user', data.result.user);

                                    if (data.result.user) {
                                        $rootScope.user = data.result.user;
                                    }

                                    $state.go('user_consumption');

                                    return;
                                } else {
                                    $window.alert('Blogos rolės');
                                }
                            }
                        }

                        $scope.loading = false;
                    }
                });
            };
        })
;