angular.module('Artakija')

    .controller('IndicatorsConsumptionController',
        function IndicatorsConsumptionController($scope, $rootScope, ApiService) {
            $rootScope.current_page = 'Skaitiklių parodymai';
            $scope.loading = true;
            $scope.from = {opened: false};

            var now = new Date();
            var d = now.getMonth();
            d = now.setMonth(d);
            var date = new Date(d);

            $scope.dateFrom = date.setDate(1);
            $scope.dateOptionsFrom = {
                dateDisabled: false,
                formatYear: 'yy',
                maxDate: new Date(2100, 5, 22),
                minDate: new Date(2015, 1, 1),
                startingDay: 1,
                datepickerMode: 'month',
                minMode: 'month'
            };

            $scope.open1 = function () {
                $scope.from.opened = true;
            };

            ApiService
                .apiCall('getBuildings', null, null, null, true)
                .then(function (data) {
                    if (!data.isError) {
                        $scope.objects = data.result;

                        if ($scope.object) {
                            angular.forEach($scope.objects, function (value) {
                                if (value.id === $scope.object.id) {
                                    $scope.objects.selected = value;
                                    $scope.config.callApi($scope.objects.selected);
                                }
                            });
                        }

                        $scope.loading = false;
                    }
                });

            ApiService
                .apiCall('getCities', null, null, null, true)
                .then(function (data) {
                    if (!data.isError) {
                        $scope.cities = data

                        if ($scope.city) {
                            angular.forEach($scope.cities, function (value) {
                                if (value === $scope.city) {
                                    $scope.cities.selected = value
                                }
                            })
                        }

                        $scope.loading = false;
                    }
                })

            $scope.config = {
                loading: $scope.loading,
                limit: 350,
                page: 1,
                delete: false,
                edit: false,
                wait: true,
                api_call: 'getIndicatorsConsumption',
            };
        })
;
