angular.module('Artakija')

    .service('LS',
        function (localStorageService) {

            // Set local storage arrayed values
            this.setArrayParam = function (name, value) {
                var lsarray = localStorageService.get(name);
                if(value){
                    if (!lsarray) {
                        lsarray = [];
                    }

                    lsarray.push(value);
                    console.log(lsarray);

                    localStorageService.set(name, lsarray);
                }else{
                    return false;
                }
            };

            // Set local storage object values
            this.setObjParam = function (name, value) {
                var lsarray = localStorageService.get(name);
                if(value){
                    localStorageService.get(name);

                    if (!lsarray) {
                        lsarray = [];
                    }

                    lsarray.push(value);

                    localStorageService.set(name, lsarray);
                }else{
                    return false;
                }
            };

            // Get local storage value
            this.getParam = function (name) {
                return localStorageService.get(name);
            };

            // Set local storage value
            this.setParam = function (name, value) {
                if(value){
                    this.getParam(name);

                    localStorageService.set(name, value);
                }else{
                    return false;
                }
            };

            // Remove param
            this.removeParam = function (name, id) {
                var ls = localStorageService.get(name);
                ls.splice(id, 1);
                localStorageService.set(name, ls);
            };

            // Remove local storage param
            this.removeParamFix = function (name, key) {
                var ls = localStorageService.get(name);
                var id = ls.indexOf(key);
                if(id > -1){
                    ls.splice(id, 1);
                    localStorageService.set(name, ls);
                }
            };

            // Remove local storage
            this.removeLS = function (name) {
                return localStorageService.remove(name);
            };

            return this;
        });