angular.module('Artakija')

    .service('ApiService',
        function ($q, $api, $window, $rootScope) {

            this.apiCall = function (api_name, id, body, get, repeat) {
                var d = $q.defer();
                var counter = 0;

                function call() {
                    $api(api_name)(id, body, get).then(function (data) {
                        data.isError = false;
                        d.resolve(data.data);
                    }, function (error) {
                        error.isError = true;

                        if (error === '') {
                            if (repeat && counter < 3) {
                                counter++;
                                setTimeout(function () {
                                    call();
                                }, 1500);
                            } else {
                                d.resolve(error);
                            }
                        } else {
                            d.resolve(error);

                            if (error.status === 403) {
                                $rootScope.logOut();
                            } else if (error.data && error.data.title) {
                                $window.alert(error.data.title);
                            } else {
                                $window.alert('Nenumatyta klaida');
                            }
                        }
                    });
                }

                call();

                return d.promise;
            };

            return this;
        });