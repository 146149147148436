angular.module('Artakija')

    .controller('UserPremisesController',
        function UserPremisesController($scope, $rootScope, $state, $stateParams, $window, ApiService) {
            console.log('Initialize UserPremisesController');
            $rootScope.current_page = 'Vartotojų patalpos';

            $scope.loading = true;
            $scope.object = $stateParams.object;

            ApiService.apiCall('getBuildings', null, null, null, true).then(function (data) {
                if(!data.isError){
                    console.log(data);
                    $scope.objects = data.result;
                    if($scope.object){
                        angular.forEach($scope.objects, function (value) {
                            if(value.id === $scope.object.id){
                                $scope.objects.selected = value;
                                $scope.config.callApi($scope.objects.selected);
                            }
                        });
                    }
                    $scope.loading = false;
                }else{
                    $scope.loading = false;
                }
            });

            $scope.config = {
                loading: $scope.loading,
                limit: 10,
                page: 1,
                delete: true,
                edit: false,
                wait: true,
                api_call: 'getUserPremises',
                columns: [
                    {name: 'consumer', display: 'Vardas/Pavadinimas'},
                    {name: 'building_name', display: 'Objektas'},
                    {name: 'number', display: 'Patalpa'},
                    {name: 'formatted_size', display: 'Plotas'}
                ]
            };

            $scope.addPremise = function () {
                if($scope.objects.selected){
                    $state.go('user_premises.add_premise', {object: $scope.objects.selected});
                }else{
                    $window.alert('Jūs nepasirinkote objekto!');
                }
            };

            $scope.config.delete = function (row) {
                ApiService.apiCall('deleteUserPremise', {id: row.id}, null, null, true).then(function (data) {
                    if(!data.isError){
                        $scope.config.refresh();
                    }
                });
            };

        })

    .controller('UserPremisesEditController',
        function UserPremisesEditController($scope, $rootScope, $stateParams, $state, $window, ApiService) {
            console.log('Initialize UserPremisesEditController');
            $rootScope.current_page = 'Vartotojų patalpos';

            $scope.object = $stateParams.object;

            ApiService.apiCall('getUsers', null, null, null, true).then(function (data) {
                if(!data.isError){
                    console.log(data);
                    $scope.persons = data;
                }
            });

            ApiService.apiCall('getPremises', {id: $scope.object.id}, null, null, true).then(function (data) {
                if(!data.isError){
                    console.log(data);
                    $scope.premises = data;
                }
            });

            $scope.from = {opened: false};
            $scope.to = {opened: false};
            $scope.dateFrom = new Date();
            $scope.dateOptionsFrom = {
                dateDisabled: false,
                formatYear: 'yy',
                maxDate: new Date(2100, 5, 22),
                minDate: new Date(),
                startingDay: 1
            };
            $scope.dateOptionsTo = {
                dateDisabled: false,
                formatYear: 'yy',
                maxDate: new Date(2100, 5, 22),
                minDate: new Date(),
                startingDay: 1
            };

            $rootScope.$watch(function () {
                return $scope.dateFrom;
            }, function () {
                console.log($scope.dateFrom);
                $scope.dateOptionsTo.minDate = $scope.dateFrom;
            });

            $scope.open1 = function() {
                $scope.from.opened = true;
            };
            $scope.open2 = function() {
                $scope.to.opened = true;
            };

            $scope.submit = function () {
                var dateFrom = $scope.dateFrom;
                var dateTo = $scope.dateTo;
                if(!$scope.persons.selected){
                    $window.alert('Pasirinkite asmenį');
                    return;
                }
                if(!$scope.premises.selected){
                    $window.alert('Pasirinkite patalpą');
                    return;
                }
                if(!dateFrom){
                    $window.alert('Pasirinkite datą nuo');
                    return;
                }
                if(!dateTo){
                    $window.alert('Pasirinkite datą iki');
                    return;
                }
                $scope.loading = true;
                dateFrom = {
                    date: {
                        year: dateFrom.getFullYear(),
                        month: dateFrom.getMonth() + 1,
                        day: dateFrom.getDate()
                    },
                    time: {
                        hour: dateFrom.getHours(),
                        minute: dateFrom.getMinutes()
                    }
                };
                dateTo = {
                    date: {
                        year: dateTo.getFullYear(),
                        month: dateTo.getMonth() + 1,
                        day: dateTo.getDate()
                    },
                    time: {
                        hour: dateTo.getHours(),
                        minute: dateTo.getMinutes()
                    }
                };
                var body = {
                    consumer: $scope.persons.selected.id,
                    premise: $scope.premises.selected.id,
                    periodFrom: dateFrom,
                    periodTo: dateTo
                };
                ApiService.apiCall('createUserPremise', null, body, null, true).then(function (data) {
                    if(!data.isError){
                        console.log(data);
                        $window.alert('Patalpa sėkmingai priskirta');
                        $state.go('user_premises', {object: $scope.object});
                        $scope.loading = false;
                    }else{
                        $scope.loading = false;
                    }
                });
            };
        })
;