angular.module('Artakija')

    .controller('IndicatorsReadingsController',
        function IndicatorsReadingsController($scope, $rootScope, ApiService, $window) {
            $rootScope.current_page = 'Skaitiklių parodymai';
            $scope.loading = true;

            ApiService
                .apiCall('getBuildings', null, null, null, true)
                .then(function (data) {
                    if (!data.isError) {
                        $scope.objects = data.result;

                        if ($scope.object) {
                            angular.forEach($scope.objects, function (value) {
                                if (value.id === $scope.object.id) {
                                    $scope.objects.selected = value;
                                    $scope.config.callApi($scope.objects.selected);
                                }
                            });
                        }

                        $scope.loading = false;
                    }
                });

            ApiService
                .apiCall('getCities', null, null, null, true)
                .then(function (data) {
                    if (!data.isError) {
                        $scope.cities = data

                        if ($scope.city) {
                            angular.forEach($scope.cities, function (value) {
                                if (value === $scope.city) {
                                    $scope.cities.selected = value
                                }
                            })
                        }

                        $scope.loading = false;
                    }
                })

            ApiService
                .apiCall('getAdministratorReports', {reportType: 'indicator'}, null, null, true)
                .then(function (data) {
                    $scope.reports = data;
                    $scope.loading = false;
                })

            $scope.config = {
                loading: $scope.loading,
                limit: 10,
                page: 1,
                delete: false,
                edit: false,
                wait: true,
                api_call: 'getIndicatorsReadings',
                filter: ['indicator_types'],
                filter_array: [],
                columns: [
                    {name: 'last_month_usage.premise.number', display: 'Butas'},
                    {name: 'indicator.indicator_on_premise_name', display: 'Patalpa'},
                    {name: 'last_month_usage.premise.client_code', display: 'Kliento kodas'},
                    {name: 'indicator.code', display: 'Apskaitos prietaiso NR'},
                    {name: 'indicator.controller.indicator_status', display: 'Apskaitos prietaiso statusas'},
                    {name: 'consumer', display: 'Vartotojas'},
                    // {name: 'last_month_usage.premise.floor', display: 'Aukštas'},
                    {name: 'last_month_usage.premise.formatted_size', display: 'Plotas m2'},
                    {name: 'to_value', display: 'Pasirinkto mėn. parodymai'},
                    {name: 'from_value', display: 'Ankstesnio mėn. parodymai'},
                    {name: 'total', display: 'Suvartojimas'},
                    {name: 'measure.name', display: 'Matas'},
                    {name: 'indicator.indicator_type.name', display: 'Apskaitos prietaiso tipas'}
                ]
            };

            $scope.setFilter = function (indicatorTypeId) {
                var index = $scope.indicator_types_selection.indexOf(indicatorTypeId)

                // is currently selected
                if (index > -1) {
                    $scope.indicator_types_selection.splice(index, 1)
                } else {
                    $scope.indicator_types_selection.push(indicatorTypeId)
                }

                var object = {key: 'indicator_types', id: $scope.indicator_types_selection}

                $scope.config.filter_array.push(object)
                $scope.config.callApi($scope.objects.selected, $scope.dateFrom)
            };

            $scope.getBuildingIndicatorTypes = function () {
                var id = {id: $scope.objects.selected.id}
                $scope.indicatorTypes = []
                $scope.indicator_types_selection = []
                $scope.config.filter_array = []

                ApiService
                    .apiCall('getBuildingIndicatorTypes', id, null, null, true)
                    .then(function (data) {
                        $scope.indicatorTypes = data

                        angular.forEach(data, function (indicatorType) {
                            $scope.indicator_types_selection.push(indicatorType.id)
                        })

                        $scope.config.callApi($scope.objects.selected, $scope.dateFrom)
                    })
            }

            $scope.from = {opened: false};
            var now = new Date();
            var d = now.getMonth();
            d = now.setMonth(d);
            var date = new Date(d);
            $scope.dateFrom = date.setDate(1);
            $scope.dateOptionsFrom = {
                dateDisabled: false,
                formatYear: 'yy',
                maxDate: new Date(2100, 5, 22),
                minDate: new Date(2015, 1, 1),
                startingDay: 1,
                datepickerMode: 'month',
                minMode: 'month'
            };

            $scope.open1 = function () {
                $scope.from.opened = true;
            };

            $scope.export = function (report) {
                if ($scope.objects.selected) {
                    var year = new Date($scope.config.dateFrom).getFullYear();
                    var month = new Date($scope.config.dateFrom).getMonth() + 1;
                    var id = {
                        id: $scope.objects.selected.id + '/' + report.id,
                    };
                    var data = {
                        year: year,
                        month: month,
                        indicator_types: $scope.indicator_types_selection
                    };

                    ApiService.apiCall('exportConsumption', id, null, data, true).then(function (data) {
                        if (!data.isError) {
                            var blob = new Blob([data]);
                            var objectUrl = (window.URL || window.webkitURL).createObjectURL(blob);
                            var link = angular.element('<a/>');

                            link.attr({
                                href: objectUrl,
                                target: '_blank',
                                download: report.full_file_name,
                            })[0].click();
                        }
                    });
                } else {
                    $window.alert('Jus nepasirinkote objekto');
                }
            };
        })
;