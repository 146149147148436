angular.module('Artakija')
    .controller('RiserControllerController',
        function RiserControllerController($scope, $rootScope, $state, $stateParams, ApiService) {
            $rootScope.current_page = 'Stovų valdikliai';
            $scope.loading = true;
            $scope.object = $stateParams.object;

            ApiService.apiCall('getRiserControllerBuildings', null, null, null, true).then(function (data) {
                if (!data.isError) {
                    $scope.objects = data.result;

                    if ($scope.object) {
                        angular.forEach($scope.objects, function (value) {
                            if (value.id === $scope.object.id) {
                                $scope.objects.selected = value;
                                $scope.config.callApi($scope.objects.selected);
                            }
                        })
                    }

                    $scope.loading = false;
                }
            })

            $scope.config = {
                loading: $scope.loading,
                api_call: 'getRiserController',
                wait: true,
                // changeResult: true,
                delete: false,
                edit: false,
                rows: [
                    {name: '0', display: 'Stovas'},
                    {name: '1', display: 'Vožtuvas'},
                    {name: '2', display: 'Vožtuvas %'},
                    {name: '3', display: 'Temperatūra'},
                    {name: '4', display: 'Nustatyta temperatūra'},
                    {name: '5', display: 'Įspėjimai'},
                ]
            }
        })
