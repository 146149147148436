angular.module('Artakija')

    .controller('AdminController',
        function AdminController($scope, $rootScope, $state, ApiService) {
            console.log('Initialize AdminController');
            $rootScope.current_page = 'Administratoriai';

            $(document).ready(function () {
                $('[data-widget="tree"]').tree();
            });

            $scope.config = {
                loading: $scope.loading,
                limit: 10,
                page: 1,
                delete: true,
                edit: false,
                objectButton: true,
                api_call: 'getAdmins',
                columns: [
                    {name: 'company.name', display: 'Juridinis asmuo'},
                    {name: 'person.full_name', display: 'Juridinio asmens atstovas'},
                    {name: 'administrator.name', display: 'Administratorius'}
                ]
            };

            $scope.config.objectView = function (item) {
                console.log(item);
                $state.go('admin.object', {row: item});
            };

            $scope.config.delete = function (row) {
                ApiService.apiCall('deleteAdmin', {id: row.id}, null, null, true).then(function (data) {
                    if(!data.isError){
                        $scope.config.refresh();
                    }
                });
            };
        })

    .controller('AdminObjectController',
        function AdminObjectController($scope, $rootScope, $stateParams) {
            console.log('Initialize AdminObjectController');
            $rootScope.current_page = 'Administratorius';

            $scope.row = $stateParams.row;

            console.log($scope.row);

            $scope.config = {
                loading: $scope.loading,
                limit: 10,
                page: 1,
                delete: false,
                edit: false,
                changeResult: true,
                api_call: 'getAdminObject',
                id: $scope.row.id,
                columns: [
                    {name: 'building.full_name', display: 'Atstovaujamas objektas'},
                    {name: 'period_from', display: 'Nuo'},
                    {name: 'period_to', display: 'Iki'}
                ]
            };

            $scope.config.changeResult = function (data) {
                var arr = [];
                angular.forEach(data.administrator_buildings, function (value) {
                    this.push(value);
                }, arr);
                $scope.result = arr;
                console.log($scope.result);
            };
        })

    .controller('AdminEditController',
        function AdminEditController($scope, $rootScope, $window, $state, ApiService) {
            console.log('Initialize AdminEditController');
            $rootScope.current_page = 'Administratorius';

            $(document).ready(function () {
                $('[data-widget="tree"]').tree();
            });

            ApiService.apiCall('getAdminCompanies', null, null, null, true).then(function (data) {
                if(!data.isError){
                    console.log(data);
                    $scope.companies = data;
                }
            });

            $scope.getPersons = function (value) {
                ApiService.apiCall('getCompanyPersons', {id: value.id}, null, null, true).then(function (data) {
                    if(!data.isError){
                        console.log(data);
                        $scope.persons = data;
                    }
                });
            };

            $scope.submit = function () {
                if(!$scope.companies.selected){
                    $window.alert('Pasirinkite juridinį asmenį');
                    return;
                }
                if(!$scope.persons.selected){
                    $window.alert('Pasirinkite atstovą');
                    return;
                }
                $scope.loading = true;
                var body = {
                    company: $scope.companies.selected.id,
                    companyPerson: $scope.persons.selected.id
                };
                ApiService.apiCall('createAdmin', null, body, null, true).then(function (data) {
                    if(!data.isError){
                        console.log(data);
                        $scope.loading = false;
                        $state.go('admin');
                    }else{
                        $scope.loading = false;
                    }
                });
            };
        })

    .controller('AdminObjectEditController',
        function AdminObjectEditController($scope, $rootScope) {
            console.log('Initialize AdminObjectEditController');
            $rootScope.current_page = 'Administratorius';
        })
;