angular.module('Artakija')

.service('Modal',
    function ($uibModal) {
        /**
         * Show a confirmation message with OK and Cancel buttons
         * @param [message] {string} A message to be displayed in the modal
         * @param [type] {null|'success'|'primary'|'info'|'warning'|'danger'} Type of modal
         * @param [title] {string} Title to be displayed
         * @param [ok] {string|null} Text on ok button
         * @param [cancel] {string|null} Text to be displayed on cancel button
         * @param [closable] {boolean} Display close button?
         */
        this.confirm = function (message, type, title, ok, cancel, closable) {
            return this.dialog(
                message,
                type,
                title,
                'Taip',
                null,
                'Ne',
                closable
            );
        };

        this.prompt = function (message, type, title, yes, no, cancel, closable) {
            return this.dialog(
                message ? message : 'modal.prompt',
                type,
                title ? title : 'modal.promptTitle',
                yes ? yes : 'modal.yes',
                no ? no : 'modal.no',
                cancel ? cancel : 'modal.cancel',
                closable
            );
        };

        this.dialog = function (message, type, title, ok, no, cancel, closable) {
            var modal = $uibModal.open({
                templateUrl: 'message_modal.html',
                controller: 'MessageModalController'
            });

            modal.data = {
                message: message,
                title: title ? title : 'modal.messageTitle',
                type: type,
                ok: ok ? ok : 'modal.ok',
                no: no ? no : null,
                cancel: cancel ? cancel : null,
                closable: closable ? closable : true
            };

            return modal.result;
        };

        this.inputPopup = function(text, type, submit, title, ok, cancel, closable) {
            var modal = $uibModal.open({
                templateUrl: 'text_modal.html',
                controller: 'InputPopupController'
            });

            modal.data = {
                text: text,
                title: title ? title : 'modal.messageTitle',
                type: type,
                ok: ok ? ok : 'modal.ok',
                cancel: cancel ? cancel : null,
                closable: closable ? closable : true,
                submit: submit
            };

            return modal.result;
        };
    })

.controller('MessageModalController',
    function MessageModalController($scope, $uibModalInstance) {
        $scope.data = $uibModalInstance.data;

        $scope.ok = function () {
            $uibModalInstance.close('ok');
        };

        $scope.no = function () {
            $uibModalInstance.close('no')
        };

        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    })

.controller('InputPopupController',
    function InputPopupController($scope, $uibModalInstance) {
        $scope.data = $uibModalInstance.data;

        $scope.ok = function () {
            $uibModalInstance.close($scope.data.input);
        };

        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    })

.controller('EditModalController',
    function EditModalController($scope, $uibModalInstance) {
        $scope.data = $uibModalInstance.data;

        console.log($scope.data);

        $scope.cancel = function() {
            $uibModalInstance.dismiss('cancel');
        }
    })


;