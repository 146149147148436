angular.module('Artakija')

    .controller('HomeController',
        function HomeController($scope, $rootScope, LS, $api, ApiService) {
            $rootScope.current_page = 'Neveikiantys skaitikliai';

            $(document).ready(function () {
                $('[data-widget="tree"]').tree();
            });

            var user = LS.getParam('user');
            var token = LS.getParam('token');

            if (user) {
                $rootScope.user = user;
            }

            if (token) {
                $api.header('X-AUTH-TOKEN', token);
            }

            // ApiService.apiCall('getCompanies', null, null, null, true).then(function (data) {
            //     if (!data.isError) {
            //         $scope.companies = data;
            //         $scope.loading = false;
            //     }
            // });

            ApiService.apiCall('getBuildings', null, null, null, true).then(function (data) {
                if (!data.isError) {
                    $scope.objects = data.result;
                    $scope.loading = false;
                }
            });

            ApiService.apiCall('getBuildingTypes', null, null, null, true).then(function (data) {
                if (!data.isError) {
                    $scope.object_types = data;
                    $scope.loading = false;
                }
            });

            $scope.config = {
                loading: $scope.loading,
                limit: 10,
                page: 1,
                delete: false,
                edit: false,
                api_call: 'getInactiveMeters',
                filter: ['company_id', 'building_id', 'building_type_id'],
                filter_array: [],
                columns: [
                    {name: 'building.object', display: 'Objektas'},
                    {name: 'indicator_name', display: 'Patalpa'},
                    {name: 'code', display: 'Skaitiklio Nr.'},
                    {name: 'timestamp', display: 'Duomenys gauti (Time point)'},
                    {name: 'controller.name', display: 'Valdiklis'}
                ]
            };

            $scope.setFilter = function (key, id) {
                var filter = $scope.config.filter;

                if (filter.indexOf(key) !== -1) {
                    var obj = {key: key, id: id};
                    $scope.config.filter_array.push(obj);
                    $scope.config.callApi();
                }
            };
        })
;