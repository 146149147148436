angular.module('Artakija')

    .controller('UserPersonEditController',
        function UserPersonEditController($scope, $rootScope, $state, $window, ApiService) {
            $rootScope.current_page = 'Vartotojai';

            $(document).ready(function () {
                $('[data-widget="tree"]').tree();
            });

            $scope.loading = true;

            ApiService.apiCall('getPremiseTypes', null, null, null, true).then(function (data) {
                if (!data.isError) {
                    $scope.flat_type = data.result;
                    $scope.loading = false;
                } else {
                    $scope.loading = false;
                }
            });

            $scope.submit = function () {
                if (!$scope.user_name) {
                    $window.alert('Įveskite vartotojo vardą');
                    return;
                }

                if (!$scope.password) {
                    $window.alert('Įveskite slaptažodį');
                    return;
                }

                if (!$scope.name) {
                    $window.alert('Įveskite vardą');
                    return;
                }

                if (!$scope.surname) {
                    $window.alert('Įveskite pavardę');
                    return;
                }

                if (!$scope.email) {
                    $window.alert('Įveskite el. paštą');
                    return;
                }

                if (!$scope.phone) {
                    $window.alert('Įveskite tel. numerį');
                    return;
                }

                if (!$scope.number) {
                    $window.alert('Įveskite numerį');
                    return;
                }

                var body = {
                    username: $scope.user_name,
                    password: $scope.password,
                    name: $scope.name,
                    lastName: $scope.surname,
                    email: $scope.email,
                    phone: $scope.phone,
                    premise_type: $scope.flat_type.selected ? $scope.flat_type.selected.id : null,
                    comment: $scope.comment ? $scope.comment : null,
                    number: $scope.number
                };

                $scope.loading = true;

                ApiService.apiCall('createUser', null, body, null, true).then(function (data) {
                    if (!data.isError) {
                        $scope.loading = false;
                        $window.alert('Vartotojas sėkmingai sukurtas');
                        $state.reload();
                    } else {
                        $scope.errors = data.data.errors;
                        $scope.loading = false;
                    }
                });
            };
        })

    .controller('UserCompanyEditController',
        function UserCompanyEditController($scope, $rootScope, $state, $window, ApiService) {
            console.log('Initialize UserCompanyEditController');
            $rootScope.current_page = 'Vartotojai';

            $(document).ready(function () {
                $('[data-widget="tree"]').tree();
            });

            $scope.submit = function () {
                if (!$scope.name) {
                    $window.alert('Įveskite vardą');
                    return;
                }
                if (!$scope.phone) {
                    $window.alert('Įveskite tel. numerį');
                    return;
                }
                if (!$scope.email) {
                    $window.alert('Įveskite el. pašto adresą');
                    return;
                }
                if (!$scope.number) {
                    $window.alert('Įveskite numerį');
                    return;
                }

                $scope.loading = true;

                var body = {
                    name: $scope.name,
                    phone: $scope.phone,
                    email: $scope.email,
                    comment: $scope.comment ? $scope.comment : null,
                    number: $scope.number
                };

                ApiService.apiCall('createCompany', null, body, null, true).then(function (data) {
                    if (!data.isError) {
                        $scope.loading = false;
                        $window.alert('Įmonė buvo sėkmingai sukurta');
                        $state.reload();
                    } else {
                        $scope.loading = false;
                    }
                })
            };
        })

    .controller('UserCompanyPersonEditController',
        function UserCompanyPersonEditController($scope, $rootScope, $state, $stateParams, $window, ApiService) {
            $rootScope.current_page = 'Vartotojai';

            $(document).ready(function () {
                $('[data-widget="tree"]').tree();
            });

            $scope.company = $stateParams.company;

            ApiService.apiCall('getCompanies', null, null, null, true).then(function (data) {
                if (!data.isError) {
                    $scope.companies = data;

                    if ($scope.company) {
                        angular.forEach($scope.companies, function (value) {
                            if (value.id === $scope.company.id) {
                                $scope.companies.selected = value;
                                $scope.config.callApi($scope.companies.selected);
                            }
                        });
                    }
                }
            });

            ApiService.apiCall('getPersons', null, null, null, true).then(function (data) {
                if (!data.isError) {
                    $scope.persons = data;
                }
            });

            $scope.config = {
                loading: $scope.loading,
                limit: 10,
                page: 1,
                delete: true,
                edit: false,
                wait: true,
                api_call: 'getCompanyPersons',
                columns: [
                    {name: 'person_name', display: 'Vardas Pavardė'},
                    {name: 'period_from', display: 'Nuo'},
                    {name: 'period_to', display: 'Iki'}
                ]
            };

            $scope.config.delete = function (row) {
                ApiService.apiCall('deleteCompanyPerson', {id: row.id}, null, null, true).then(function (data) {
                    if (!data.isError) {
                        $scope.config.refresh();
                    }
                });
            };

            $scope.from = {opened: false};
            $scope.to = {opened: false};
            $scope.dateFrom = new Date();
            $scope.dateOptionsFrom = {
                dateDisabled: false,
                formatYear: 'yy',
                maxDate: new Date(2100, 5, 22),
                minDate: new Date(),
                startingDay: 1
            };
            $scope.dateOptionsTo = {
                dateDisabled: false,
                formatYear: 'yy',
                maxDate: new Date(2100, 5, 22),
                minDate: new Date(),
                startingDay: 1
            };

            $rootScope.$watch(function () {
                return $scope.dateFrom;
            }, function () {
                $scope.dateOptionsTo.minDate = $scope.dateFrom;
            });

            $scope.open1 = function () {
                $scope.from.opened = true;
            };
            $scope.open2 = function () {
                $scope.to.opened = true;
            };

            $scope.submit = function () {
                var dateFrom = $scope.dateFrom;
                var dateTo = $scope.dateTo;

                if (!$scope.persons.selected) {
                    $window.alert('Pasirinkite asmenį');
                    return;
                }

                if (!$scope.companies.selected) {
                    $window.alert('Pasirinkite įmonę');
                    return;
                }

                if (!dateFrom) {
                    $window.alert('Pasirinkite datą nuo');
                    return;
                }

                if (!dateTo) {
                    $window.alert('Pasirinkite datą iki');
                    return;
                }

                $scope.loading = true;
                dateFrom = {
                    date: {
                        year: dateFrom.getFullYear(),
                        month: dateFrom.getMonth() + 1,
                        day: dateFrom.getDate()
                    },
                    time: {
                        hour: dateFrom.getHours(),
                        minute: dateFrom.getMinutes()
                    }
                };
                dateTo = {
                    date: {
                        year: dateTo.getFullYear(),
                        month: dateTo.getMonth() + 1,
                        day: dateTo.getDate()
                    },
                    time: {
                        hour: dateTo.getHours(),
                        minute: dateTo.getMinutes()
                    }
                };

                var body = {
                    person: $scope.persons.selected.id,
                    company: $scope.companies.selected.id,
                    comment: $scope.comment ? $scope.comment : null,
                    periodFrom: dateFrom,
                    periodTo: dateTo
                };

                ApiService.apiCall('createCompanyPerson', null, body, null, true).then(function (data) {
                    if (!data.isError) {
                        console.log(data);
                        $scope.loading = false;
                        $window.alert('Juridinio asmens atstovas buvo sėkmingai pridėtas');
                        $state.reload();
                    } else {
                        $scope.loading = false;
                    }
                });
            };
        })
;