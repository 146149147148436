angular.module('Artakija')
    .controller('EclControllersController', function EclControllersController($scope, $rootScope, $state, ApiService) {
        $rootScope.current_page = 'Ecl valdikliai';
        $scope.loading = true;

        $scope.dataTableOpt = {
            'retrieve': true,
            aLengthMenu: [10, 25, 50, 100, -1]
        };

        $scope.changePagination = function (e) {
            $scope.config.limit = e;
            localStorage.setItem('perPage', e);
            $scope.config.callApi($scope.objects.selected);
        };

        ApiService.apiCall('getEclBuildings', null, null, null, true).then(function (data) {
            if (!data.isError) {
                $scope.objects = data.result;

                if ($scope.object) {
                    angular.forEach($scope.objects, function (value) {
                        if (value.id === $scope.object.id) {
                            $scope.objects.selected = value;
                            $scope.config.callApi($scope.objects.selected);
                        }
                    })
                }

                $scope.loading = false;
            }
        });

        $scope.config = {
            loading: $scope.loading,
            limit: localStorage.getItem('perPage') || 10,
            page: 1,
            delete: false,
            edit: false,
            wait: true,
            api_call: 'getBuildingEclControllers',
            columns: [
                {name: 'ipAddress', display: 'Ip adresas'},
                {name: 'manufacturer', display: 'Gamintojas'},
                {name: 'model', display: 'Modelis'},
                {name: 'port', display: 'Prievadas'},
                {name: 'socketProtocol', display: 'Protokolas'},
                {name: 'unitId', display: 'Sistemos ID'},
                {name: 'controllerId', display: 'Valdiklio ID'},
            ]
        }

        $scope.config.edit = function (row) {
            $state.go('ecl_controllers.edit_ecl_controller', {controller: row.controllerId});
        };
    })

    .controller('EclControllersEditController',
        function EclControllersEditController($scope, $rootScope, $state, $stateParams, $window, ApiService) {
            $rootScope.current_page = 'Ecl valdikliai';
            $scope.loading = true;
            $scope.object = $stateParams.object;

            ApiService.apiCall('getEclController',
                {controller: $stateParams.controller},
                null,
                null,
                true
            ).then(function (data) {
                if (!data.isError) {
                    $scope.unit_id = data.unit_id;
                    $scope.port = data.port;
                    $scope.socket_protocol = data.socket_protocol;
                    $scope.rdDateTime = data.rd_date_time;
                    $scope.desTComfort = data.des_t_comfort;
                    $scope.desTSaving = data.des_t_saving;
                    $scope.cirDuiTMode = data.circ_dui_t_mode;
                    $scope.scheme = data.scheme;
                    $scope.e_circuit_modes = data.e_circuit_modes.split(",");
                    $scope.e_circuit_status = data.e_circuit_status.split(",");
                    $scope.image = data.image;

                    $scope.loading = false;
                } else {
                    $scope.loading = false;
                }
            })

            $scope.setDesTComfort = function () {
                ApiService.apiCall('setDesTComfort',
                    {controller: $stateParams.controller},
                    {value: $scope.desTComfort},
                    null,
                    true
                ).then(function (data) {
                    if (!data.isError) {
                        alert('Pakeista');

                        $scope.loading = false;
                    } else {
                        $scope.loading = false;
                    }
                });
            }

            $scope.setDesTSaving = function () {
                console.log($stateParams);

                ApiService.apiCall('setDesTSaving',
                    {controller: $stateParams.controller},
                    {value: $scope.desTSaving},
                    null,
                    true
                ).then(function (data) {
                    if (!data.isError) {
                        alert('Pakeista');

                        $scope.loading = false;
                    } else {
                        $scope.loading = false;
                    }
                })
            }

        })