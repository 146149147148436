angular.module('Artakija')

    .controller('UserIndicatorsReadingsController',
        function UserIndicatorsReadingsController($scope, $rootScope, ApiService) {
            $rootScope.current_page = 'Skaitiklių parodymai';
            $scope.loading = true;
            $scope.from = {opened: false};

            var now = new Date();
            var d = now.getMonth();
            d = now.setMonth(d);
            var date = new Date(d);

            $scope.dateFrom = date.setDate(1);
            $scope.dateOptionsFrom = {
                dateDisabled: false,
                formatYear: 'yy',
                maxDate: new Date(2100, 5, 22),
                minDate: new Date(2015, 1, 1),
                startingDay: 1,
                datepickerMode: 'month',
                minMode: 'month'
            };

            $scope.open1 = function() {
                $scope.from.opened = true;
            };

            ApiService
                .apiCall('getUserBuildings', null, null, null, true)
                .then(function (data) {
                    if (!data.isError) {
                        $scope.objects = data.result;

                        if ($scope.object) {
                            angular.forEach($scope.objects, function (value) {
                                if (value.id === $scope.object.id) {
                                    $scope.objects.selected = value;
                                    $scope.config.callApi($scope.objects.selected);
                                }
                            });
                        }

                        $scope.loading = false;
                    }
                })

            $scope.config = {
                loading: $scope.loading,
                limit: 350,
                page: 1,
                delete: false,
                edit: false,
                wait: true,
                api_call: 'getUserIndicatorsReadings',
                columns: [
                    {name: 'indicator.indicator_on_premise_name', display: 'Patalpa'},
                    {name: 'last_month_usage.premise.client_code', display: 'Kliento kodas'},
                    {name: 'indicator.code', display: 'Apskaitos prietaiso NR'},
                    {name: 'indicator.controller.indicator_status', display: 'Apskaitos prietaiso statusas'},
                    {name: 'consumer', display: 'Vartotojas'},
                    // {name: 'last_month_usage.premise.floor', display: 'Aukštas'},
                    {name: 'last_month_usage.premise.formatted_size', display: 'Plotas m2'},
                    {name: 'to_value', display: 'Pasirinkto mėn. parodymai'},
                    {name: 'from_value', display: 'Ankstesnio mėn. parodymai'},
                    {name: 'total', display: 'Suvartojimas'},
                    {name: 'measure.name', display: 'Matas'},
                    {name: 'indicator.indicator_type.name', display: 'Apskaitos prietaiso tipas'}
                ]
            };
        })
