angular.module('Artakija')

    .controller('IndicatorsController',
        function IndicatorsController($scope, $rootScope, ApiService) {
            console.log('Initialize IndicatorsController');
            $rootScope.current_page = 'Skaitikliai';
            $scope.loading = true;

            $scope.dataTableOpt = {
                "retrieve": true,
                aLengthMenu: [10, 25, 50, 100, -1]
            };

            $scope.changePagination = function(e){
                $scope.config.limit = e;
                localStorage.setItem('perPage', e);
                $scope.config.callApi($scope.objects.selected);
            };

            ApiService.apiCall('getBuildings', null, null, null, true).then(function (data) {
                if(!data.isError){
                    $scope.objects = data.result;
                    if($scope.object){
                        angular.forEach($scope.objects, function (value) {
                            if(value.id === $scope.object.id){
                                $scope.objects.selected = value;
                                $scope.config.callApi($scope.objects.selected);
                            }
                        });
                    }
                    $scope.loading = false;
                }
            });

            $scope.config = {
                loading: $scope.loading,
                limit: localStorage.getItem('perPage') || 10,
                page: 1,
                delete: false,
                edit: false,
                wait: true,
                api_call: 'getIndicators',
                columns: [
                    {name: 'name', display: 'Patalpa'},
                    {name: 'controller.name', display: 'Valdiklis'},
                    {name: 'indicator_type.name', display: 'Skaitiklio tipas'},
                    {name: 'code', display: 'Skaitiklio numeris'},
                    {name: 'formatted_curr_value', display: 'Esama skaitiklio reikšmė'},
                    {name: 'formatted_total_month_usage', display: 'Suvartojimas'}
                ]
            };
        })
;