angular.module('Artakija')

    .controller('BuildingReadingInsertController',
        function BuildingReadingInsertController($scope, $rootScope, $state, ApiService, $window) {
            $rootScope.current_page = 'Bendras namo šilumos įvedimas';

            ApiService
                .apiCall('getBuildings', null, null, null, true)
                    .then(function (data) {
                    if (!data.isError) {
                        $scope.objects = data.result;
                        $scope.loading = false;
                    } else {
                        $scope.loading = false;
                    }
                });

            ApiService
                .apiCall('getCities', null, null, null, true)
                .then(function (data) {
                    if (!data.isError) {
                        $scope.cities = data

                        if ($scope.city) {
                            angular.forEach($scope.cities, function (value) {
                                if (value === $scope.city) {
                                    $scope.cities.selected = value
                                }
                            })
                        }

                        $scope.loading = false;
                    }
                })

            $scope.getIndicatorsByBuilding = function (data) {
                ApiService
                    .apiCall('getIndicatorsByBuilding', {id: data.id}, null, null, true)
                    .then(function (data) {
                        if (!data.isError) {
                            $scope.indicators = data;
                            $scope.usage_value = null;
                            $scope.loading = false;
                        } else {
                            $scope.loading = false;
                        }
                    });
            };

            $scope.getBuildingUsageValue = function (data) {
                ApiService
                    .apiCall('getBuildingUsageValue', {id: data})
                    .then(function (data) {
                        $scope.usage_value = data.usageValue;
                    })
            };

            $scope.setUsageValueAndId = function () {
                if ($scope.indicators.selected.hasOwnProperty('lines')) {
                    $scope.blmul_id = $scope.indicators.selected.lines.id;
                    this.getBuildingUsageValue($scope.blmul_id);
                } else {
                    $scope.blmul_id = null;
                    $scope.usage_value = null;
                }
            };

            $scope.submit = function () {
                if (!$scope.objects.selected) {
                    $window.alert('Pasirinkite objektą');
                    return;
                }

                if (!$scope.indicators.selected) {
                    $window.alert('Pasirinkite indikatorių');
                    return;
                }

                if (!$scope.usage_value) {
                    $window.alert('Įveskite bendro šilumos skaitiklio reikšmę');
                    return;
                }

                $scope.loading = true;

                var body = {
                    buildingLastMonthUsage: $scope.indicators.selected.id,
                    usageValue: $scope.usage_value,
                    id: $scope.blmul_id
                };

                ApiService
                    .apiCall('insertBuildingReading', {id: $scope.objects.selected.id}, body, null, true)
                    .then(function (data) {
                        if (!data.isError) {
                            $window.alert('Duomenys išsaugoti');
                            $scope.usage_value = body.usageValue;
                            $scope.blmul_id = body.id;
                            $state.go('building_reading_insert');
                            $scope.loading = false;
                        } else {
                            $scope.loading = false;
                        }
                    });
            };
        })
;