angular.module('Artakija')

    .controller('PremisesController',
        function PremisesController($scope, $rootScope, $state, $stateParams, ApiService, $window) {
            console.log('Initialize PremisesController');
            $rootScope.current_page = 'Patalpos';
            $scope.loading = true;
            $scope.object = $stateParams.object;

            ApiService.apiCall('getBuildings', null, null, null, true).then(function (data) {
                if(!data.isError){
                    $scope.objects = data.result;
                    if($scope.object){
                        angular.forEach($scope.objects, function (value) {
                            if(value.id === $scope.object.id){
                                $scope.objects.selected = value;
                                $scope.config.callApi($scope.objects.selected);
                            }
                        });
                    }
                    $scope.loading = false;
                }
            });

            $scope.config = {
                loading: $scope.loading,
                limit: 10,
                page: 1,
                delete: true,
                edit: true,
                api_call: 'getPremises',
                wait: true,
                changeResult: true,
                columns: [
                    {name: 'premise_name', display: 'Pastatas'},
                    {name: 'building.building_type.name', display: 'Tipas'},
                    {name: 'floor', display: 'Aukštas'},
                    {name: 'number', display: 'NR'},
                    {name: 'formatted_size', display: 'Dydis'}
                ]
            };

            $scope.config.edit = function (row) {
                $state.go('premises.edit_premises', {id: row.id, object: $scope.objects.selected});
            };

            $scope.config.delete = function (row) {
                ApiService.apiCall('deletePremise', {id: row.id}, null, null, true).then(function (data) {
                    if(!data.isError){
                        $scope.config.refresh();
                    }
                });
            };

            $scope.config.changeResult = function (data) {
                var arr = [];
                angular.forEach(data, function (value) {
                    value.premise_name = value.building.building_type.name + ' ' + value.building.street + ' ' + value.building.number;
                    this.push(value);
                }, arr);
                $scope.result = arr;
            };

            $scope.addPremise = function () {
                if($scope.objects.selected){
                    $state.go('premises.edit_premises', {object: $scope.objects.selected});
                }else{
                    $window.alert('Jūs nepasirinkote objekto!');
                }
            }
        })

    .controller('PremisesEditController',
        function PremisesEditController($scope, $rootScope, $stateParams, $state, $window, ApiService) {
            console.log('Initialize PremisesEditController');
            $rootScope.current_page = 'Patalpos';

            $scope.object = $stateParams.object;
            $scope.premise_id = $stateParams.id;

            ApiService.apiCall('getPremiseTypes', null, null, null, true).then(function (data) {
                if(!data.isError){
                    $scope.flat_type = data.result;
                    if($scope.premise_id){
                        ApiService.apiCall('getPremise', {id: $scope.premise_id}, null, null, true).then(function (data) {
                            if(!data.isError){
                                $scope.number = data.number;
                                $scope.size = data.size;
                                $scope.floor = data.floor;
                                angular.forEach($scope.flat_type, function (value) {
                                    if(value.id === data.premise_type.id){
                                        $scope.flat_type.selected = value;
                                    }
                                });
                            }
                        });
                    }
                }
            });

            $scope.submit = function () {
                var api = $scope.premise_id ? 'editPremise' : 'createPremise';
                var id = $scope.premise_id ? {id: $scope.premise_id} : null;
                if(!$scope.number){
                    $window.alert('Įveskite numerį');
                    return;
                }
                if(!$scope.size){
                    $window.alert('Įveskite plotą');
                    return;
                }
                if(!$scope.floor){
                    $window.alert('Įveskite aukštą');
                    return;
                }
                if(!$scope.flat_type.selected){
                    $window.alert('Pasirinkite buto tipą');
                    return;
                }
                $scope.loading = true;
                var body = {
                    number: $scope.number,
                    size: $scope.size,
                    floor: $scope.floor,
                    premiseType: $scope.flat_type.selected.id,
                    building: $scope.object.id
                };
                ApiService.apiCall(api, id, body, null, true).then(function (data) {
                    if(!data.isError){
                        $window.alert('Patalpa sėkmingai pridėta');
                        $state.go('premises', {object: $scope.object});
                        $scope.loading = false;
                    }else{
                        $scope.loading = false;
                    }
                });
            };
        })
;