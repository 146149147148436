angular.module('Artakija')

    .controller('MyPremisesController',
        function MyPremisesController($scope, $rootScope, ApiService) {
            console.log('Initialize MyPremisesController');
            $rootScope.current_page = 'Mano patalpos';

            $(document).ready(function () {
                $('[data-widget="tree"]').tree();
            });

            $scope.config = {
                loading: $scope.loading,
                limit: 10,
                page: 1,
                delete: false,
                edit: false,
                api_call: 'getMyPremises',
                columns: [
                    {name: 'building', display: 'Pastatas'},
                    {name: 'premise_type', display: 'Tipas'},
                    {name: 'floor', display: 'Aukštas'},
                    {name: 'number', display: 'NR'},
                    {name: 'formatted_size', display: 'Dydis'}
                ]
            };
        })
;