angular.module('Artakija')

    .directive('myTable',
        function MyTableDirective(ApiService, $api) {
            return {
                restrict: 'E',
                templateUrl: 'data_table.html',
                scope: {
                    result: '=',
                    config: '=',
                    loading: '='
                },
                controller: ['$scope', 'Modal',
                    function SmartTableDirectiveController($scope, Modal) {
                        $scope.config.limit = $scope.config.limit || 50;
                        $scope.config.page = $scope.config.page || 1;

                        $scope.edit = function (item) {
                            $scope.config.edit(item);
                        };

                        $scope.remove = function (item) {
                            Modal.confirm('Ar tikrai norite ištrinti?', null, 'Trynimas').then(function (sel) {
                                $scope.config.delete(item);
                            });
                        };

                        $scope.goToObject = function (item) {
                            $scope.config.objectView(item);
                        };

                        $scope.config.refresh = function () {
                            if ($scope.object) {
                                $scope.config.callApi($scope.object);
                            } else {
                                $scope.callApi();
                            }
                        };

                        $scope.parseName = function (name, object) {
                            var n = name.split('.');
                            for (var i = 0; i < n.length; i++) {
                                if (typeof object[n[i]] === 'undefined') {
                                    return;
                                }
                                object = object[n[i]];
                            }
                            return object;
                        };

                        $scope.config.callApi = function (object, date) {
                            var number = $scope.config.limit || 10;
                            var start = (($scope.config.page || 1) - 1) * number;
                            var api = $scope.config.api_call;
                            var get;

                            if (date) {
                                $scope.config.dateFrom = date;
                                var year = new Date(date).getFullYear();
                                var month = new Date(date).getMonth() + 1;
                                get = {limit: number, offset: start, year: year, month: month};
                            } else {
                                get = {limit: number, offset: start};
                            }

                            var object_id;

                            if ($scope.config.filter && $scope.config.filter_array.length > 0) {
                                angular.forEach($scope.config.filter_array, function (data) {
                                    var filter_name = 'filter[' + data.key + ']';
                                    get[filter_name] = data.id;
                                });
                            }

                            $scope.object = object;
                            $scope.config.object = object;
                            object ? object_id = {id: object.id} : null;

                            if (api) {
                                $scope.loading = true;
                                ApiService.apiCall(api, object_id, null, get, true).then(function (data) {
                                    data.result ? $scope.result = data.result : $scope.result = data;

                                    if (data.hasOwnProperty('meta')) {
                                        data.meta ? $scope.meta = data.meta : $scope.meta = null;
                                        data.meta.usageTotal ? $scope.$parent.usageTotal = data.meta.usageTotal : $scope.$parent.usageTotal = [];
                                    }

                                    if ($scope.config.changeResult) {
                                        $scope.config.changeResult($scope.result);
                                    }

                                    $scope.loading = false;
                                });
                            } else {
                                $scope.loading = false;
                            }
                        };

                        $scope.callApi = function () {
                            var number = $scope.config.limit || 10;
                            var start = (($scope.config.page || 1) - 1) * number;
                            var api = $scope.config.api_call;
                            var wait = $scope.config.wait;
                            var get = {limit: number, offset: start};
                            var id;
                            $scope.config.id ? id = {id: $scope.config.id} : id = null;

                            if (!wait) {
                                if (api) {
                                    $scope.loading = true;
                                    ApiService.apiCall(api, id, null, get, true).then(function (data) {
                                        data.result ? $scope.result = data.result : $scope.result = data;
                                        data.meta ? $scope.meta = data.meta : $scope.meta = null;
                                        data.meta.usageTotal ? $scope.$parent.usageTotal = data.meta.usageTotal : $scope.$parent.usageTotal = [];

                                        if ($scope.config.changeResult) {
                                            $scope.config.changeResult($scope.result);
                                        }

                                        $scope.loading = false;
                                    });
                                } else {
                                    $scope.loading = false;
                                }
                            }
                        };

                    }]
            }
        })
;