angular.module('Artakija')
    .directive('riserController',
        function RiserControllerDirective(ApiService) {
            return {
                restrict: 'E',
                templateUrl: 'riser_controllers_table.html',
                scope: {
                    result: '=',
                    config: '=',
                    loading: '='
                },
                controller: ['$scope', function SmartTableDirectiveController($scope) {
                    $scope.goToObject = function (item) {
                        $scope.config.objectView(item);
                    };

                    $scope.config.refresh = function () {
                        if ($scope.object) {
                            $scope.config.callApi($scope.object);
                        } else {
                            $scope.callApi();
                        }
                    };

                    $scope.parseName = function (name, object) {
                        var n = name.split('.');

                        for (var i = 0; i < n.length; i++) {
                            if (typeof object[n[i]] === 'undefined') {
                                return;
                            }

                            object = object[n[i]];
                        }

                        return object;
                    };

                    $scope.config.callApi = function (object) {
                        var api = $scope.config.api_call;
                        var get;
                        var object_id;

                        if ($scope.config.filter && $scope.config.filter_array.length > 0) {
                            angular.forEach($scope.config.filter_array, function (data) {
                                var filter_name = 'filter[' + data.key + ']';
                                get[filter_name] = data.id;
                            });
                        }

                        $scope.object = object;
                        $scope.config.object = object;
                        object ? object_id = {id: object.id} : null;

                        if (api) {
                            $scope.loading = true;
                            ApiService.apiCall(api, object_id, null, get, true).then(function (data) {
                                data.result ? $scope.result = data.result : $scope.result = data;

                                if ($scope.config.changeResult) {
                                    $scope.config.changeResult($scope.result);
                                }

                                $scope.loading = false;
                            });
                        } else {
                            $scope.loading = false;
                        }
                    };
                }]
            }
        })
;